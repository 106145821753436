import React from 'react';

export const IconCross: React.FC<{ className?: string }> = (props) => {
  return (
    <svg className={props.className} width="23" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m22.87 3.986-7.45 7.449 7.45 7.449-3.986 3.985-7.45-7.449-7.448 7.45L0 18.883l7.449-7.45L0 3.987 3.986 0l7.449 7.449L18.884 0l3.985 3.986Z"
        fill="currentColor"
      />
    </svg>
  );
};
