import React, { useMemo } from 'react';

import { ButtonProps } from './Button.atom.types';
import * as Styled from './Button.styled';

export const Button: React.FC<ButtonProps> = ({ br, isLoading, ...props }) => {
  const $br = useMemo(() => {
    if (!props.size) {
      return br;
    }

    switch (props.size) {
      case 'small':
        return 's';
      case 'middle':
        return 'm';
      case 'large':
        return 'l';
    }
  }, [br, props.size]);

  return (
    <Styled.Container
      {...props}
      $br={$br || 'm'}
      $fs={props.fs}
      $bordered={props.bordered === undefined ? true : props.bordered}
      disabled={props.disabled || isLoading}
      $isLoading={isLoading}
    >
      {props.children}
    </Styled.Container>
  );
};
