import { useOrganizationApi } from 'entities/organization';
import { useProfile } from 'entities/profile';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganization, setRole } from 'storage/profile';
import { AppState } from 'storage/store';

import { useOnceByPredicate } from '../../utils/hooks/useOnceByPredicate';
import * as S from './OrganizationSelect.styled';

type Props = {
  className?: string;
};

export const OrganizationSelect: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const org = useOrganizationApi();
  const profile = useProfile();

  const options = useMemo(() => {
    if (!profile.data?.organizations) {
      return;
    }

    return profile.data.organizations.map((org) => ({
      id: org.id,
      value: `${org.displayName}`,
      label: org.displayName,
    }));
  }, [profile.data?.organizations]);

  const valueInStore = useSelector((state: AppState) => state.profile.organization);

  const currentValue = useMemo(() => {
    if (valueInStore) {
      return {
        id: valueInStore.id,
        value: `${valueInStore.name}`,
        label: valueInStore.name,
      };
    }
    if (org.data) {
      return {
        id: org.data.id,
        value: `${org.data.name}`,
        label: org.data.name,
      };
    }
    return;
  }, [org, valueInStore]);

  const onSelect = useCallback((_: any, value: any) => {
    dispatch(setOrganization({ id: value.id, name: value.value }));
  }, []);

  const displayValue = useMemo(() => {
    if ((options?.length || 0) < 2) {
      return org.data?.name && ` - ${org.data.name}`;
    }

    return (
      <>
        {' - '}
        <S.Select onSelect={onSelect} options={options} value={currentValue} />
      </>
    );
  }, [options, onSelect, currentValue]);

  useOnceByPredicate(
    () => {
      if (currentValue) {
        dispatch(setOrganization({ id: currentValue.id.toString(), name: currentValue.value }));
      }
    },
    () => !!currentValue?.id,
  );

  useEffect(() => {
    dispatch(setRole(profile.data?.organizations ? 'superuser' : 'admin'));
  }, [profile.data?.organizations]);

  return <S.Container className={props.className}>FI Portal {displayValue}</S.Container>;
};
