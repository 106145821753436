import { Guest } from 'kit/organisms/Guest';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthToken } from 'shared/api';

import * as S from './PublicRoute.styled';

export const PublicRoute = () => {
  const token = useAuthToken();
  const location = useLocation();

  const redirectUrl = location.state?.from || '/dashboard';

  if (token) {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <S.Container>
      <Guest>
        <Outlet />
      </Guest>
    </S.Container>
  );
};
