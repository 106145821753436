import { LoginPage } from 'pages/login';
import { MainPage } from 'pages/Main';
import { ResetPassword } from 'pages/ResetPassword';
import { SetPassword } from 'pages/SetPassword';
import { Settings } from 'pages/Settings';
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

const notFound = <Navigate to={'/dashboard'} />;

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={'/'}>
      <Route Component={PublicRoute}>
        <Route path="login" Component={LoginPage} />
        <Route path="reset-password" Component={ResetPassword} />
        <Route path="set-password" Component={SetPassword} />
      </Route>

      <Route Component={PrivateRoute}>
        <Route path={'dashboard'} Component={MainPage} />
        <Route path={'settings'} Component={Settings} />
        <Route path={'*'} element={notFound} />
        <Route index element={notFound} />
      </Route>
    </Route>,
  ),
);
