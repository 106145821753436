import React, { PropsWithChildren } from 'react';

import * as Styled from './Guest.organism.styled';

type Props = {
  className?: string;
};

export const Guest: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <Styled.Frame className={props.className}>
      <Styled.Container>{props.children}</Styled.Container>
      <Styled.Waves />
    </Styled.Frame>
  );
};
