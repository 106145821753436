import './defaults.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { AuthContextProvider } from 'shared/api';
import { store } from 'storage/store';

import { antPaletteOverride } from './ant.colors.override';
import { router } from './app/App.content';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <ConfigProvider theme={antPaletteOverride}>
          <ReduxProvider store={store}>
            <RouterProvider router={router} />
          </ReduxProvider>
        </ConfigProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
};

export default App;
