import waves from 'assets/eko_guest-bg.svg';
import { gaps } from 'kit/constants/sizes';
import styled from 'styled-components';

export const Frame = styled.div`
  background: var(--eko-dark-bg-primary);
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
  position: relative;
  margin: 0 ${gaps.s}px;
  z-index: 101;
`;

export const Waves = styled.div`;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(${waves});
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 100;
}

`;
