import { Select as BaseSelect } from 'kit/molecules/Input/Select';
import styled from 'styled-components';

export const Container = styled('div')``;

export const Select = styled(BaseSelect).attrs({
  popupMatchSelectWidth: false,
  isTextLabelDropdown: true,
  placement: 'bottomLeft',
})`
  display: inline-block;
  font-family: inherit;
  .ant-select {
  }
`;
