import { IconCross } from 'kit/atoms/IconCross';
import React, { PropsWithChildren } from 'react';

import * as Styled from './Popup.organism.styled';

type Props = {
  isVisible: boolean;
  onClickYes?: VoidFunction;
  onClickNo: VoidFunction;
  title: JSX.Element | string;
  titleInHeader?: boolean;
  subText?: JSX.Element | string;
  yesBtnText?: JSX.Element | string;
  noBtnText?: JSX.Element | string;
  disableControls?: boolean;
  isLoading: boolean;
  description?: JSX.Element | string;
  onClosed?: VoidFunction;
  modalClassName?: string;
  className?: string;
  disableClose?: boolean;
  autoFocus?: boolean;
};

export const Popup: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <>
      <Styled.GlobalStyles />
      <Styled.Container
        wrapClassName="eko-modal-wrapper"
        onClosed={props.onClosed}
        isOpen={props.isVisible}
        toggle={props.onClickNo}
        autoFocus={props.autoFocus}
        centered
        returnFocusAfterClose
        className={props.className}
        modalClassName={props.modalClassName}
        container={'#modal-root'}
      >
        {(!props.disableClose || (props.title && props.titleInHeader)) && (
          <Styled.HeaderContainer $titleInPortfolio={props.titleInHeader || false}>
            {props.title && props.titleInHeader && <Styled.BaseTitle>{props.title}</Styled.BaseTitle>}
            {!props.disableClose && (
              <Styled.CloseButton onClick={props.onClickNo}>
                <IconCross />
              </Styled.CloseButton>
            )}
          </Styled.HeaderContainer>
        )}
        <Styled.ContentContainer>
          {props.title && !props.titleInHeader && <Styled.Title>{props.title}</Styled.Title>}
          {props.subText && <Styled.Subtitle>{props.subText}</Styled.Subtitle>}
          {props.children && <Styled.ChildrenContent>{props.children}</Styled.ChildrenContent>}
          {!props.disableControls && (
            <Styled.ActionsContainer $disableBottomMargin={props.disableClose}>
              <Styled.Confirm onClick={props.onClickYes} disabled={props.isLoading}>
                {props.yesBtnText}
              </Styled.Confirm>
              {props.noBtnText && <Styled.Decline onClick={props.onClickNo}>{props.noBtnText}</Styled.Decline>}
            </Styled.ActionsContainer>
          )}
          {props.description && <Styled.Description>{props.description}</Styled.Description>}
        </Styled.ContentContainer>
      </Styled.Container>
    </>
  );
};
