import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiEndpoints, ApiMapTypes, ApiResponseError, request, RequestParams } from 'services/api';

import { AuthContext } from './api.context';

export const useAuthToken = () => {
  const { authToken } = useContext(AuthContext);
  return authToken;
};

type Options<Response, Result = Response> = {
  onSuccess?: (value: Result) => void;
  onError?: (error: ApiResponseError) => void;
  mapper?: (response: Response) => Result;
  enabled?: boolean;
};

export const useGetApi = <Endpoint extends ApiEndpoints, R>(
  params: Omit<RequestParams<Endpoint, 'get', R>, 'authToken' | 'method'>,
  options?: Options<R>,
) => {
  const authToken = useAuthToken();

  return useQuery([params.endpoint, params.params], () => request({ ...params, method: 'get', authToken }), {
    enabled: options?.enabled,
    onSuccess: options?.onSuccess,
    onError: options?.onError,
    select: options?.mapper,
  });
};

export const usePostApi = <
  Endpoint extends ApiEndpoints,
  Response extends ApiMapTypes[Endpoint]['post']['response'],
  Result = Response,
>(
  endpoint: Endpoint,
  options?: Options<Response, Result>,
) => {
  const authToken = useAuthToken();
  const { mapper, ...restOptions } = options || { mapper: (val) => val };

  return useMutation<Result, ApiResponseError, ApiMapTypes[Endpoint]['post']['params']>(
    [endpoint, authToken, mapper],
    (params) =>
      request({
        method: 'post',
        endpoint,
        params,
        // eslint-disable-next-line
        /*@ts-ignore*/
        mapper,
        authToken,
      }),
    { ...restOptions },
  );
};

export const useGetFileApi = <
  Endpoint extends ApiEndpoints,
  Response extends ApiMapTypes[Endpoint]['post']['response'],
  Result = Response,
>(
  endpoint: Endpoint,
  options?: Options<Response, Result>,
) => {
  const authToken = useAuthToken();
  const { mapper, ...restOptions } = options || { mapper: (val) => val };

  return useMutation<Result, ApiResponseError, ApiMapTypes[Endpoint]['get']['params']>(
    [endpoint, authToken, mapper],
    (params) =>
      request({
        method: 'get',
        endpoint,
        params,
        /*@ts-ignore*/
        mapper,
        authToken,
      }),
    { ...restOptions },
  );
};
