import React from 'react';

const Login = React.lazy(() => import('./Login'));

export const LoginPage = () => {
  return (
    <React.Suspense>
      <Login />
    </React.Suspense>
  );
};
