import { gaps, getEkoBorderRadius } from 'kit/constants/sizes';
import { css } from 'styled-components';
import { media } from 'utils/ui';

export const errorColors = css`
  &-status-error {
    color: var(--eko-error-color) !important;
    border-color: var(--eko-error-color) !important;
  }
`;

export const boxShadow = css`
  box-shadow: none !important;
`;

export const INPUT_BORDER_WIDTH = 2;
export const border = css`
  border-color: var(--eko-fifth-color) !important;
  transition: border-color 0.3s;
  border-style: solid;

  &:hover,
  &:focus {
    border-color: var(--eko-fifth-color) !important;
  }
  border-radius: ${getEkoBorderRadius({ $br: 'm' })};
  border-width: ${INPUT_BORDER_WIDTH}px;
`;

export const borderColor = css`
  border-color: var(--eko-fifth-color) !important;

  &:hover,
  &:focus {
    border-color: var(--eko-fifth-color) !important;
  }
`;

export const borderedStyles = css`
  --input-border-width: 2px;
  border: var(--eko-fifth-color) var(--input-border-width) solid;
  color: var(--eko-third-color);
  padding: ${gaps.xxs}px ${gaps.m}px;

  ${media.md`
    padding: ${gaps.s}px ${gaps.xl}px;
  `}

  &:hover, &:focus {
    border-color: var(--eko-primary-color);
  }

  &:focus {
    box-shadow: 0 0 0 calc(var(--input-border-width) * 2) var(--eko-primary-color-light) !important;
  }
`;
export const underlinedBorder = css`
  ${borderColor}

  &, &:hover, &:focus {
    ${border};
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    ${boxShadow};
  }
`;
