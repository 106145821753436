import { Layout, Menu } from 'antd';
import { Logout } from 'features/Logout';
import { accentFont, baseFont, fontSize } from 'kit/constants/fonts';
import { gaps } from 'kit/constants/sizes';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { media } from 'utils/ui';

export const Container = styled(Layout)`
  &.ant-layout {
    min-height: 100vh;
    ${baseFont};
    ${media.lg``}
  }
`;

export const Header = styled(Layout.Header).attrs({ $fs: 'xxl' })`
  position: sticky;
  top: 0;
  z-index: 1100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  color: var(--eko-primary-color);
  background: var(--eko-primary-color-light-6);
  padding: ${gaps.s}px;
  ${fontSize};
  ${accentFont};

  ${media.md`
    padding: ${gaps.m}px;
  `}
`;

export const Sidebar = styled(Layout.Sider).attrs({ $fs: 'm', collapsedWidth: '0', breakpoint: 'md', width: 250 })`
  &.ant-layout-sider {
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 1101;

    ${media.md`
      position: sticky;
  `}
  }

  & .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: ${gaps.s}px;
    ${media.md`
      padding-top: ${gaps.m}px;
    `}
  }

  &.ant-layout-sider-light {
    & .ant-layout-sider-zero-width-trigger {
      background: var(--eko-primary-color-light-6);
      bottom: 40px;
      height: 60px;
      top: unset;
      inset-inline-end: -80px;
      width: 80px;

      & .anticon-bars svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  &.ant-layout-sider-has-trigger {
    padding-bottom: 70px;
  }

  & .ant-layout-sider-trigger {
    height: auto;
    ${fontSize};
    padding: ${gaps.s}px;

    ${media.md`
      padding: ${gaps.m}px;
  `}
  }
`;

export const MenuContainer = styled(Menu).attrs({ $fs: 'm' })`
  ${fontSize};

  & .ant-menu-item .ant-menu-item-icon {
    font-size: inherit;
  }

  & a {
    text-decoration: none;
  }
`;

export const MenuLink = styled(NavLink)`
  font-size: inherit;
  ${baseFont};
`;

export const LogoutButton = styled(Logout)`
  &.ant-btn {
    width: 100%;
    padding: ${gaps.s}px;
  }
`;

export const Wrapper = styled(Layout)`
  background: var(--eko-background-color);
`;

export const Content = styled(Layout.Content)`
  margin: ${gaps.s}px;

  ${media.md`
    margin: ${gaps.xxl}px;
  `}
`;

export const Footer = styled(Layout.Footer).attrs({ $fs: 'm' })`
  padding: ${gaps.s}px;
  color: var(--eko-primary-color-light-6);
  user-select: none;
  background: var(--eko-primary-color-light-6);
  ${fontSize};

  ${media.md`
    padding: ${gaps.m}px;
  `}
`;
