import { SelectProps as ASelectProps } from 'antd';
import React, { useCallback } from 'react';

import * as Styled from './Select.molecule.styled';

type SelectProps = ASelectProps & {
  fullWidth?: boolean;
  before?: React.ReactNode;
  isTextLabelDropdown?: boolean;
  valueControl?: React.ReactNode;
};

const defaultIcon = <Styled.Icon />;

export const Select = React.forwardRef<any, SelectProps>(
  ({ suffixIcon, dropdownRender, fullWidth, before, isTextLabelDropdown, valueControl, className, ...props }, ref) => {
    const renderDropdown: typeof dropdownRender = useCallback(
      (children: React.ReactElement) => {
        return (
          <>
            <Styled.Dropdown $fullWidth={fullWidth}>
              {before}
              {children}
            </Styled.Dropdown>
          </>
        );
      },
      [fullWidth, before],
    );

    return (
      <Styled.Container className={className}>
        {valueControl}
        <Styled.Select
          {...props}
          onChange={props.onChange as any}
          onSelect={props.onSelect as any}
          onDeselect={props.onDeselect as any}
          filterOption={props.filterOption as any}
          filterSort={props.filterSort as any}
          $hideValue={!!valueControl}
          $isTextLabelDropdown={isTextLabelDropdown}
          ref={ref}
          $fullWidth={fullWidth}
          suffixIcon={suffixIcon === undefined ? defaultIcon : suffixIcon}
          dropdownRender={dropdownRender || renderDropdown}
        />
      </Styled.Container>
    );
  },
);
