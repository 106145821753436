import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { profileSlice } from '../profile';

const reducer = combineReducers({
  profile: profileSlice.reducer,
});

export const store = configureStore({
  reducer,
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
