import React, { FC, useCallback, useState } from 'react';

import { useLogout } from '../../entities/token';
import * as S from './Logout.styled';

type Props = {
  className?: string;
};

export const Logout: FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const onHideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onShowModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const onLogout = useLogout();

  return (
    <>
      <S.Container onClick={onShowModal} className={props.className}>
        Log out
      </S.Container>
      <S.ConfirmationContainer
        yesBtnText={'Log out'}
        onClickYes={onLogout}
        title={'Are you sure you want to leave?'}
        isLoading={false}
        onClickNo={onHideModal}
        isVisible={showModal}
      />
    </>
  );
};
