import { DashboardOutlined, SettingOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { OrganizationSelect } from 'features/OrganizationSelect';
import React, { PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './Layout.styled';

type Props = {
  sideBarContent?: React.ReactNode;
};

type MenuItem = Required<MenuProps>['items'][number];

type GetItemParams = {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  onClick?: VoidFunction;
};

function getItem({ label, key, icon, children, onClick }: GetItemParams): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem({
    label: <S.MenuLink to={'/dashboard'}>Dashboard</S.MenuLink>,
    key: 'dashboard',
    icon: <DashboardOutlined />,
  }),
  getItem({
    label: <S.MenuLink to={'/settings'}>Change password</S.MenuLink>,
    key: 'settings',
    icon: <SettingOutlined />,
  }),
];

export const Layout: React.FC<PropsWithChildren<Props>> = (props) => {
  const location = useLocation();

  const defaultSelected = useMemo(() => {
    switch (location.pathname) {
      case '/dashboard': {
        return ['dashboard'];
      }
      case '/settings': {
        return ['settings'];
      }
    }
    return ['dashboard'];
  }, [location.pathname]);

  const navigationContent = useMemo(
    () => props.sideBarContent || <S.MenuContainer selectedKeys={defaultSelected} mode="inline" items={items} />,
    [props.sideBarContent, defaultSelected],
  );

  return (
    <S.Container>
      <S.Sidebar theme={'light'} zeroWidthTriggerStyle={{}}>
        {navigationContent}
        <S.LogoutButton />
      </S.Sidebar>

      <S.Wrapper>
        <S.Header theme={'light'}>
          <OrganizationSelect />
        </S.Header>
        <S.Content>{props.children}</S.Content>
        <S.Footer>.</S.Footer>
      </S.Wrapper>
    </S.Container>
  );
};
