import { useCallback, useContext } from 'react';
import { AuthContext, usePostApi } from 'shared/api';

export const useGetToken = () => {
  const { setAuthToken } = useContext(AuthContext);

  const onSuccess = useCallback((token: string) => {
    setAuthToken(token);
    sessionStorage.setItem('authToken', token);
  }, []);

  return usePostApi('serve/token', { onSuccess, mapper: ({ access }) => `Bearer ${access}` });
};
