import React from 'react';

const SettingsComponent = React.lazy(() => import('./Settings.page'));

export const Settings = () => {
  return (
    <React.Suspense>
      <SettingsComponent />
    </React.Suspense>
  );
};
