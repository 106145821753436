export const antPaletteOverride = {
  token: {
    colorErrorBgHover: '#fff1f1',
    colorErrorBorder: '#ffccc8',
    colorErrorBorderHover: '#ffa39f',
    colorErrorHover: '#ff7876',
    colorError: '#d40d0d',
    colorErrorActive: '#d9363d',
    colorErrorTextHover: '#ff7a76',
    colorErrorText: '#ff4d4а',
    colorErrorTextActive: '#d9363в',
    colorLink: '#5fa776',
    colorTextBase: '#6a6d74',
    colorBgBase: '#ffffff',
    colorSuccess: '#5fa776',
    colorPrimary: '#5fa776',
    colorInfo: '#5fa776',
    colorWarning: '#fbbd00',
  },
};
