import React from 'react';

export const IconArrow: React.FC<{ className?: string }> = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99996 0.249961C7.1598 0.249961 7.3198 0.311055 7.44184 0.433086L13.6918 6.68309C13.9361 6.9273 13.9361 7.32277 13.6918 7.56684C13.4476 7.8109 13.0521 7.81105 12.8081 7.56684L6.99996 1.75871L1.19183 7.56684C0.947617 7.81105 0.552147 7.81105 0.308084 7.56684C0.0640211 7.32262 0.0638647 6.92715 0.308084 6.68309L6.55808 0.433086C6.68012 0.311055 6.84012 0.249961 6.99996 0.249961Z"
        fill="currentColor"
      />
    </svg>
  );
};
