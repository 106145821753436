import React from 'react';

const Main = React.lazy(() => import('./Main.page'));

export const MainPage = () => {
  return (
    <React.Suspense>
      <Main />
    </React.Suspense>
  );
};
