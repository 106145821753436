import { useEffect, useRef } from 'react';

export const useOnceByPredicate = (effect: () => void, predicate: () => boolean) => {
  const wasCalledRef = useRef(false);

  const shouldBeCalled = !wasCalledRef.current && predicate();

  useEffect(() => {
    if (shouldBeCalled) {
      effect();
      wasCalledRef.current = true;
    }
  }, [shouldBeCalled]);
};
