import React from 'react';

const SetPasswordComponent = React.lazy(() => import('./SetPassword.page'));

export const SetPassword = () => {
  return (
    <React.Suspense>
      <SetPasswordComponent />
    </React.Suspense>
  );
};
