import React, { PropsWithChildren, useState } from 'react';

const storedToken = sessionStorage.getItem('authToken') || null;

export const AuthContext = React.createContext<{
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
}>({
  authToken: storedToken,
  setAuthToken: () => {
    return;
  },
});

export const AuthContextProvider: React.FC<PropsWithChildren> = (props) => {
  const [authToken, setAuthToken] = useState<string | null>(storedToken);

  return <AuthContext.Provider value={{ authToken, setAuthToken }}>{props.children}</AuthContext.Provider>;
};
