import React from 'react';

const ResetPasswordComponent = React.lazy(() => import('./ResetPassword.page'));

export const ResetPassword = () => {
  return (
    <React.Suspense>
      <ResetPasswordComponent />
    </React.Suspense>
  );
};
