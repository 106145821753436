import React, { PropsWithChildren } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthToken } from 'shared/api';
import { Layout } from 'templates/Layout';

export const PrivateRoute: React.FC<PropsWithChildren> = (props) => {
  const token = useAuthToken();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  return <Layout>{props.children || <Outlet />}</Layout>;
};
